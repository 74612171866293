// File Path: E:\data\vscodeproject\cv-comparison-app\frontend\src\components\ui\dialog.js
import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { X } from "lucide-react"
import PropTypes from 'prop-types'

// If you have a utils file with the cn function, import it correctly
// import { cn } from "../../lib/utils"

// If you don't have a cn function, you can define it here:
const cn = (...classes) => classes.filter(Boolean).join(" ")

/**
 * @typedef {Object} DialogProps
 * @property {React.ReactNode} [children] - The content of the dialog
 * @property {string} [className] - Additional CSS classes to apply to the dialog
 */

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

/**
 * DialogPortal component for rendering dialog content in a portal
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The content to render in the portal
 * @param {...any} props.props - Any additional props to pass to the portal
 * @returns {JSX.Element} The rendered DialogPortal component
 */
const DialogPortal = ({
  children,
  ...props
}) => (
  <DialogPrimitive.Portal {...props}>
    <div className="fixed inset-0 z-50 flex items-start justify-center sm:items-center">
      {children}
    </div>
  </DialogPrimitive.Portal>
)

DialogPortal.propTypes = {
  children: PropTypes.node.isRequired
}

/**
 * DialogOverlay component for rendering a backdrop behind the dialog
 * @param {Object} props - The component props
 * @param {string} [props.className] - Additional CSS classes to apply to the overlay
 * @param {...any} props.props - Any additional props to pass to the overlay
 * @returns {JSX.Element} The rendered DialogOverlay component
 */
const DialogOverlay = React.forwardRef(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm transition-all duration-100 data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in",
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

DialogOverlay.propTypes = {
  className: PropTypes.string
}

/**
 * DialogContent component for rendering the main content of the dialog
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The content of the dialog
 * @param {string} [props.className] - Additional CSS classes to apply to the content
 * @param {...any} props.props - Any additional props to pass to the content
 * @returns {JSX.Element} The rendered DialogContent component
 */
const DialogContent = React.forwardRef(({ children, className, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "fixed z-50 grid w-full gap-4 rounded-b-lg border bg-background p-6 shadow-lg animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 sm:max-w-lg sm:rounded-lg sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0",
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName

DialogContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

/**
 * DialogHeader component for rendering the header of the dialog
 * @param {Object} props - The component props
 * @param {string} [props.className] - Additional CSS classes to apply to the header
 * @param {...any} props.props - Any additional props to pass to the header
 * @returns {JSX.Element} The rendered DialogHeader component
 */
const DialogHeader = ({
  className,
  ...props
}) => (
  <div
    className={cn("flex flex-col space-y-1.5 text-center sm:text-left", className)}
    {...props}
  />
)

DialogHeader.propTypes = {
  className: PropTypes.string
}

/**
 * DialogFooter component for rendering the footer of the dialog
 * @param {Object} props - The component props
 * @param {string} [props.className] - Additional CSS classes to apply to the footer
 * @param {...any} props.props - Any additional props to pass to the footer
 * @returns {JSX.Element} The rendered DialogFooter component
 */
const DialogFooter = ({
  className,
  ...props
}) => (
  <div
    className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)}
    {...props}
  />
)

DialogFooter.propTypes = {
  className: PropTypes.string
}

/**
 * DialogTitle component for rendering the title of the dialog
 * @param {Object} props - The component props
 * @param {string} [props.className] - Additional CSS classes to apply to the title
 * @param {...any} props.props - Any additional props to pass to the title
 * @returns {JSX.Element} The rendered DialogTitle component
 */
const DialogTitle = React.forwardRef(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn("text-lg font-semibold leading-none tracking-tight", className)}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

DialogTitle.propTypes = {
  className: PropTypes.string
}

/**
 * DialogDescription component for rendering the description of the dialog
 * @param {Object} props - The component props
 * @param {string} [props.className] - Additional CSS classes to apply to the description
 * @param {...any} props.props - Any additional props to pass to the description
 * @returns {JSX.Element} The rendered DialogDescription component
 */
const DialogDescription = React.forwardRef(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

DialogDescription.propTypes = {
  className: PropTypes.string
}

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}