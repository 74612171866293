// File Path: E:\data\vscodeproject\cv-comparison-app\frontend\src\App.js
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import LoadingSpinner from './components/LoadingSpinner';
import Header from './components/Header';
import Footer from './components/Footer';

const Home = lazy(() => import('./pages/Home'));
const Comparison = lazy(() => import('./pages/Comparison'));
const UserAccount = lazy(() => import('./pages/UserAccount'));
const Privacy = lazy(() => import('./components/Privacy'));
const Login = lazy(() => import('./components/Login'));
const PricingPage = lazy(() => import('./pages/PricingPage'));
const PartnerUpPage = lazy(() => import('./pages/PartnerUpPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));

const LazyRoute = ({ component: Component }) => (
  <Suspense fallback={<LoadingSpinner />}>
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  </Suspense>
);

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <div className="App flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow overflow-y-auto">
            <Routes>
              <Route path="/" element={<LazyRoute component={Home} />} />
              <Route path="/comparison" element={<LazyRoute component={Comparison} />} />
              <Route path="/account" element={<LazyRoute component={UserAccount} />} />
              <Route path="/privacy" element={<LazyRoute component={Privacy} />} />
              <Route path="/login" element={<LazyRoute component={Login} />} />
              <Route path="/pricing" element={<LazyRoute component={PricingPage} />} />
              <Route path="/partner-up" element={<LazyRoute component={PartnerUpPage} />} />
              <Route path="/contact" element={<LazyRoute component={ContactPage} />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ErrorBoundary>
  );
}

export default App;