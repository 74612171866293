// File Path: E:\data\vscodeproject\cv-comparison-app\frontend\src\components\Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * @typedef {Object} FooterLink
 * @property {string} text - The display text for the footer link
 * @property {string} to - The URL path for the footer link
 */

/**
 * Footer component for the application
 * @param {Object} props - The component props
 * @param {FooterLink[]} [props.additionalLinks] - Additional links to be displayed in the footer
 * @returns {JSX.Element} The rendered Footer component
 */
const Footer = ({ additionalLinks = [] }) => {
  const currentYear = new Date().getFullYear();

  /**
   * Renders a footer link
   * @param {FooterLink} link - The link object to render
   * @param {number} index - The index of the link
   * @returns {JSX.Element} The rendered footer link
   */
  const renderFooterLink = (link, index) => (
    <Link
      key={index}
      to={link.to}
      className="text-sm text-blue-600 hover:text-blue-800 transition-colors duration-300 mr-4"
    >
      {link.text}
    </Link>
  );

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-blue-200 to-blue-300 shadow-md z-50">
      <div className="container mx-auto px-4 py-3 flex flex-col md:flex-row justify-between items-center">
        <div className="text-sm mb-2 md:mb-0">
          © {currentYear} SkillsMatrix. All rights reserved
        </div>
        <div className="flex flex-wrap justify-center md:justify-end">
          {renderFooterLink({ text: 'Privacy Policy', to: '/privacy' })}
          {additionalLinks.map(renderFooterLink)}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  additionalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
};

export default Footer;