// File Path: E:\data\vscodeproject\cv-comparison-app\frontend\src\components\ui\alert.js
import * as React from "react"
import { cva } from "class-variance-authority"
import PropTypes from 'prop-types'

// If you have a utils file with the cn function, import it correctly
// import { cn } from "../../lib/utils"

// If you don't have a cn function, you can define it here:
const cn = (...classes) => classes.filter(Boolean).join(" ")

/**
 * @typedef {Object} AlertProps
 * @property {React.ReactNode} [children] - The content of the alert
 * @property {string} [className] - Additional CSS classes to apply to the alert
 * @property {string} [variant='default'] - The variant of the alert ('default', 'destructive', etc.)
 */

const alertVariants = cva(
  "relative w-full rounded-lg border p-4 [&>svg~*]:pl-7 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground",
  {
    variants: {
      variant: {
        default: "bg-background text-foreground",
        destructive:
          "border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

/**
 * Alert component for displaying important messages
 * @param {AlertProps} props - The component props
 * @returns {JSX.Element} The rendered Alert component
 */
const Alert = React.forwardRef(({ className, variant, ...props }, ref) => {
  // Validate variant prop
  if (variant && !['default', 'destructive'].includes(variant)) {
    console.warn(`Invalid variant prop: ${variant}. Falling back to 'default'.`)
    variant = 'default'
  }

  return (
    <div
      ref={ref}
      role="alert"
      className={cn(alertVariants({ variant }), className)}
      {...props}
    />
  )
})

Alert.displayName = "Alert"

Alert.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'destructive'])
}

/**
 * @typedef {Object} AlertTitleProps
 * @property {React.ReactNode} [children] - The content of the alert title
 * @property {string} [className] - Additional CSS classes to apply to the alert title
 */

/**
 * AlertTitle component for the title of an Alert
 * @param {AlertTitleProps} props - The component props
 * @returns {JSX.Element} The rendered AlertTitle component
 */
const AlertTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn("mb-1 font-medium leading-none tracking-tight", className)}
    {...props}
  />
))

AlertTitle.displayName = "AlertTitle"

AlertTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

/**
 * @typedef {Object} AlertDescriptionProps
 * @property {React.ReactNode} [children] - The content of the alert description
 * @property {string} [className] - Additional CSS classes to apply to the alert description
 */

/**
 * AlertDescription component for the description of an Alert
 * @param {AlertDescriptionProps} props - The component props
 * @returns {JSX.Element} The rendered AlertDescription component
 */
const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm [&_p]:leading-relaxed", className)}
    {...props}
  />
))

AlertDescription.displayName = "AlertDescription"

AlertDescription.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export { Alert, AlertTitle, AlertDescription }