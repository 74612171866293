// File Path: E:\data\vscodeproject\cv-comparison-app\frontend\src\index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/main.css';  // or whatever your main CSS file is named
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);