// File Path: E:\data\vscodeproject\cv-comparison-app\frontend\src\components\Header.js
import React, { useState, useDeferredValue } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SignUpForm from './SignUpForm';
import Login from './Login';
import ContactForm from './ContactForm';
import { Dialog, DialogContent } from './ui/dialog';

const Header = () => {
  const [hoveredItem, setHoveredItem] = useState('');
  const deferredHoveredItem = useDeferredValue(hoveredItem);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Pricing', path: '/pricing' },
    { name: 'Partner Up', path: '/partner-up' },
    { name: 'Contact Us', action: () => setIsContactOpen(true) },
  ];

  const buttonClasses = "px-3 py-2 rounded transition-colors duration-300";

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleLoginOpen = () => setIsLoginOpen(true);
  const handleLoginClose = () => setIsLoginOpen(false);
  const handleRegisterOpen = () => setIsRegisterOpen(true);
  const handleRegisterClose = () => setIsRegisterOpen(false);
  const handleContactClose = () => setIsContactOpen(false);

  const renderNavItems = (isMobile) => (
    navItems.map((item) => (
      <li key={item.name}>
        {item.path ? (
          <Link
            to={item.path}
            className={`${isMobile ? 'block' : ''} px-3 py-2 rounded transition-colors duration-300 ${
              deferredHoveredItem === item.name && !isMobile
                ? 'text-indigo-900 bg-white bg-opacity-50 scale-105'
                : 'text-gray-800 hover:bg-white hover:bg-opacity-30'
            }`}
            onMouseEnter={() => setHoveredItem(item.name)}
            onMouseLeave={() => setHoveredItem('')}
            onClick={() => isMobile && setIsMenuOpen(false)}
          >
            {item.name}
          </Link>
        ) : (
          <button
            onClick={() => {
              item.action();
              if (isMobile) setIsMenuOpen(false);
            }}
            className={`${isMobile ? 'block w-full text-left' : ''} px-3 py-2 rounded transition-colors duration-300 ${
              deferredHoveredItem === item.name && !isMobile
                ? 'text-indigo-900 bg-white bg-opacity-50 scale-105'
                : 'text-gray-800 hover:bg-white hover:bg-opacity-30'
            }`}
            onMouseEnter={() => setHoveredItem(item.name)}
            onMouseLeave={() => setHoveredItem('')}
          >
            {item.name}
          </button>
        )}
      </li>
    ))
  );

  return (
    <header className="fixed top-0 left-0 right-0 bg-gradient-to-r from-blue-200 to-blue-300 text-gray-800 py-4 shadow-md z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="text-2xl md:text-3xl font-bold">SkillsMatrix</div>
          <button 
            onClick={toggleMenu} 
            className={`md:hidden text-gray-800 focus:outline-none z-50 ${buttonClasses}`}
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          >
            {isMenuOpen ? (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
          <nav className="hidden md:block pr-4">
            <ul className="flex items-center justify-end space-x-4">
              {renderNavItems(false)}
              <li className="flex space-x-2">
                <button 
                  onClick={handleLoginOpen}
                  className={`bg-indigo-500 text-white hover:bg-blue-600 ${buttonClasses}`}
                >
                  Login
                </button>
                <button 
                  onClick={handleRegisterOpen}
                  className={`bg-green-500 text-white hover:bg-indigo-600 ${buttonClasses}`}
                >
                  Register
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      
      <Dialog open={isLoginOpen} onOpenChange={setIsLoginOpen}>
        <DialogContent 
          className="sm:max-w-[425px]"
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => e.preventDefault()}
        >
          <Login onClose={handleLoginClose} />
        </DialogContent>
      </Dialog>

      <Dialog open={isRegisterOpen} onOpenChange={setIsRegisterOpen}>
        <DialogContent 
          className="sm:max-w-[425px]"
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => e.preventDefault()}
        >
          <SignUpForm onClose={handleRegisterClose} />
        </DialogContent>
      </Dialog>

      <Dialog open={isContactOpen} onOpenChange={setIsContactOpen}>
        <DialogContent 
          className="sm:max-w-[425px]"
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => e.preventDefault()}
        >
          <ContactForm onClose={handleContactClose} />
        </DialogContent>
      </Dialog>

      {/* Mobile menu overlay */}
      <div 
        className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-40 transition-opacity duration-300 ease-in-out ${
          isMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={toggleMenu}
      ></div>
      
      {/* Mobile menu */}
      <div 
        className={`fixed top-0 left-0 w-full h-full bg-white shadow-lg z-50 transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } overflow-y-auto`}
      >
        <nav className="container mx-auto px-4 py-4">
          <ul className="flex flex-col space-y-2">
            {renderNavItems(true)}
            <li>
              <Link
                to="/privacy"
                className="block px-3 py-2 rounded text-gray-800 hover:bg-blue-100 transition-colors duration-300"
                onClick={() => setIsMenuOpen(false)}
              >
                Privacy Policy
              </Link>
            </li>
            <li className="flex flex-col space-y-2 pt-2">
              <button 
                onClick={() => {
                  handleLoginOpen();
                  setIsMenuOpen(false);
                }}
                className={`bg-blue-500 text-white hover:bg-blue-600 ${buttonClasses}`}
              >
                Login
              </button>
              <button 
                onClick={() => {
                  handleRegisterOpen();
                  setIsMenuOpen(false);
                }}
                className={`bg-indigo-500 text-white hover:bg-indigo-600 ${buttonClasses}`}
              >
                Register
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;